<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Layanan
        </h5>
        <small class="text-muted">Arsitektur Domain Layanan SPBE.</small>
      </b-col>
      <b-col
        v-if="layananData.is_verified"
        cols="12"
        class="mb-1"
      >
        <b-alert
          :variant="(layananData.is_verified === 1) ? 'success' : 'warning'"
          show
        >
          <h4
            v-if="layananData.is_verified !== 1"
            class="alert-heading"
          >
            Catatan Perbaikan
          </h4>
          <div
            v-if="layananData.is_verified !== 1 && layananData.keterangan !== ''"
            class="alert-body"
          >
            <span>{{ layananData.keterangan }}</span>
          </div>
          <div
            v-if="layananData.is_verified === 1"
            class="alert-body"
          >
            <span>Terverifikasi</span>
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="!layananData.nama_layanan"
        md="12"
        class="mb-1"
      >
        <b-form-group
          v-b-tooltip.hover.top="'Arsitektur Domain Layanan sesuai dengan Renja'"
          label-for="vi-master_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Pilih Nama Layanan atau Tambah Baru"
            rules="required"
          >
            <v-select
              id="vi-master_layanan"
              v-model="master_layanan"
              required
              name="master_layanan"
              :options="referenceData.services_data_list"
              placeholder="Pilih Nama Layanan atau Tambah Baru"
              label="label"
              @input="setSelected"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Domain Layanan"
          label-for="vi-service_domain_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Domain Layanan"
            rules="required"
          >
            <v-select
              id="vi-service_domain_id"
              v-model="service_domain_id"
              required
              name="service_domain_id"
              :options="referenceData.ref_domain_layanan"
              placeholder="Domain Layanan"
              label="nama"
              @input="getAreaLayanan()"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
          label="Area Layanan"
          label-for="vi-service_area_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Area Layanan"
            rules="required"
          >
            <v-select
              id="vi-service_area_id"
              v-model="service_area_id"
              required
              name="service_area_id"
              :options="daftarAreaLayanan"
              placeholder="Area Layanan"
              label="nama"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan nama dari layanan yang dihasilkan oleh unit kerja/perangkat daerah.'"
          label="Nama Layanan"
          label-for="vi-nama_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Nama Layanan"
            rules="required"
          >
            <b-form-input
              id="vi-nama_layanan"
              v-model="layananData.nama_layanan"
              required
              name="nama_layanan"
              placeholder="Nama Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan penjelasan tujuan dari layanan.'"
          label="Tujuan Layanan"
          label-for="vi-tujuan_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Tujuan Layanan"
            rules="required"
          >
            <b-form-input
              id="vi-tujuan_layanan"
              v-model="layananData.tujuan_layanan"
              required
              name="tujuan_layanan"
              placeholder="Tujuan Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan uraian penjelasan dari fungsi-fungsi yang terdapat dalam layanan.'"
          label="Fungsi Layanan"
          label-for="vi-fungsi_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Fungsi Layanan"
            rules="required"
          >
            <b-form-input
              id="vi-fungsi_layanan"
              v-model="layananData.fungsi_layanan"
              required
              name="fungsi_layanan"
              placeholder="Fungsi Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan unit kerja/perangkat daerah yang melaksanakan layanan.'"
          label="Unit Pelaksana"
          label-for="vi-unit_pelaksana"
        >
          <validation-provider
            #default="{ errors }"
            name="Unit Pelaksana"
            rules="required"
          >
            <b-form-input
              id="vi-unit_pelaksana"
              v-model="layananData.unit_pelaksana"
              required
              name="unit_pelaksana"
              placeholder="Unit Pelaksana"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan kementerian/lembaga terkait dengan layanan.'"
          label="Kementrian / Lembaga terkait"
          label-for="vi-data_instansi_id"
        >
          <validation-provider
            #default="{ errors }"
            name="Kementrian / Lembaga terkait"
            rules="required"
          >
            <v-select
              id="vi-data_instansi_id"
              v-model="data_instansi_id"
              required
              multiple
              name="data_instansi_id"
              :options="referenceData.ref_instansi"
              placeholder="Kementrian / Lembaga terkait"
              label="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan target pengguna layanan.'"
          label="Target Layanan"
          label-for="vi-target_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Target Layanan"
            rules="required"
          >
            <b-form-input
              id="vi-target_layanan"
              v-model="layananData.target_layanan"
              required
              name="target_layanan"
              placeholder="Target Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          v-b-tooltip.hover.top="'Merupakan model dari layanan.'"
          label="Metode Layanan"
          label-for="vi-metode_layanan"
        >
          <validation-provider
            #default="{ errors }"
            name="Metode Layanan"
            rules="required"
          >
            <b-form-textarea
              id="vi-metode_layanan"
              v-model="layananData.metode_layanan"
              rows="4"
              required
              name="metode_layanan"
              placeholder="Metode Layanan"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BAlert, BFormInput, BRow, BCol, BFormGroup, BFormTextarea, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    coreData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      layananData: { is_verified: false },
      clearanceStatus: null,
      referenceData: {
        services_data_list: [],
        ref_area_layanan: [],
        ref_domain_layanan: [],
        ref_instansi: [],
      },
      data_utama_id: '',
      layanan_id: '',
      master_layanan: '',
      service_domain_id: '',
      service_area_id: '',
      data_instansi_id: [],
      daftarAreaLayanan: [],
      daftarMasterLayanan: { layanan_id: 'new', label: 'Tambah Layanan Baru' },
    }
  },
  watch: {
    actionStep() {
      this.saveLayanan()
    },
  },
  created() {
    this.data_utama_id = Number(this.$route.params.id)
  },
  mounted() {
    this.getReference()
  },
  methods: {
    getReference() {
      this.$http.get('/clearance/services-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
            if (res.data.data.services_data_list) {
              const pics = [this.daftarMasterLayanan]
              res.data.data.services_data_list.map(value => {
                const pic = value
                pic.label = `${value.nama_layanan} / ${value.domain_layanan} / ${value.area_layanan}`
                pics.push(pic)
                return true
              })
              this.referenceData.services_data_list = pics
            }

            this.setReference()
          }
        })
    },
    setReference() {
      this.$http.get('/clearance/services-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.coreData.data_utama_id ? this.coreData.data_utama_id : this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.layananData = res.data.data

            this.service_domain_id = {
              domain_layanan_id: this.layananData.domain_layanan_id,
              nama: this.layananData.domain_layanan,
            }
            this.getAreaLayanan()
            this.service_area_id = {
              area_layanan_id: this.layananData.area_layanan_id,
              nama: this.layananData.area_layanan,
            }
            this.data_instansi_id = this.layananData.instansi_terkait
          }
        })
    },
    setSelected() {
      if (this.master_layanan.layanan_id !== 'new') {
        this.layananData = this.master_layanan
        this.service_domain_id = {
          domain_layanan_id: this.layananData.domain_layanan_id,
          nama: this.layananData.domain_layanan,
        }
        this.getAreaLayanan()

        if (this.layananData.area_layanan_id) {
          this.service_area_id = {
            area_layanan_id: this.layananData.area_layanan_id,
            nama: this.layananData.area_layanan,
          }
        }
        this.data_instansi_id = []
        this.layananData.instansi_terkait.map(value => this.data_instansi_id.push(value))
      }
    },
    getAreaLayanan() {
      this.daftarAreaLayanan.splice(0)
      this.service_area_id = ''

      const filterVal = this.service_domain_id.domain_layanan_id
      const refArea = this.referenceData.ref_area_layanan
      refArea.map(contact => {
        if (contact.domain_layanan_id === filterVal) {
          this.daftarAreaLayanan.push(contact)
        }
        return true
      })
    },
    saveLayanan() {
      document.getElementById('loading-bg').style.display = 'block'
      const listgov = []
      this.data_instansi_id.map(value => listgov.push(value.instansi_id))

      const metaUtama = {
        data_utama_id: this.coreData.data_utama_id ? this.coreData.data_utama_id : this.data_utama_id,
        instansi_id: this.layananData.instansi_id,
        domain_layanan_id: this.service_domain_id ? this.service_domain_id.domain_layanan_id : 1,
        area_layanan_id: this.service_area_id ? this.service_area_id.area_layanan_id : 1,
        nama_layanan: this.layananData.nama_layanan,
        tujuan_layanan: this.layananData.tujuan_layanan,
        fungsi_layanan: this.layananData.fungsi_layanan,
        unit_pelaksana: this.layananData.unit_pelaksana,
        instansi_terkait: listgov,
        target_layanan: this.layananData.target_layanan,
        metode_layanan: this.layananData.metode_layanan,
      }
      if (this.layananData.layanan_id !== '') {
        metaUtama.layanan_id = this.layananData.layanan_id
      }
      this.$http.post('/clearance/services-data', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
          data_utama_id: this.coreData.data_utama_id ? this.coreData.data_utama_id : this.data_utama_id,
        },
      })
        .then(res => {
          const data = {
            status: true,
            msg: res.data.data,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          const data = {
            status: false,
            msg: error.response.data.error,
          }
          this.$emit('step-completed', data)
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>
