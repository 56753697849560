<template>
  <validation-observer ref="formInfra">
    <b-form :id="`form-edit-inf-${appData.id}`">
      <b-card class="border-bottom">
        <b-row>
          <b-col
            v-if="appData.is_verified === 1 || appData.is_verified === -1"
            cols="12"
            class="mb-1"
          >
            <b-alert
              :variant="(appData.is_verified === 1) ? 'success' : 'warning'"
              show
            >
              <h4
                v-if="appData.is_verified !== 1"
                class="alert-heading"
              >
                Catatan Perbaikan
              </h4>
              <div
                v-if="appData.is_verified !== 1 && appData.keterangan !== ''"
                class="alert-body"
              >
                <span>{{ appData.keterangan }}</span>
              </div>
              <div
                v-else
                class="alert-body"
              >
                <span>Terverifikasi</span>
              </div>
            </b-alert>
          </b-col>
          <b-col md="4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Domain Infrastruktur"
              label-for="vi-inf_domain_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Domain Infrastruktur"
                rules="required"
              >
                <v-select
                  id="vi-inf_domain_id"
                  v-model="inf_domain_id"
                  required
                  name="inf_domain_id"
                  :options="referenceData.ref_domain_infra"
                  placeholder="Domain Infrastruktur"
                  label="nama"
                  @input="getAreaInfra()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Area Infrastruktur"
              label-for="vi-inf_area_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Area Infrastruktur"
                rules="required"
              >
                <v-select
                  id="vi-inf_area_id"
                  v-model="inf_area_id"
                  required
                  name="inf_area_id"
                  :options="daftarAreaInfra"
                  placeholder="Area Infra"
                  label="nama"
                  @input="getKategoriInfra()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan kode model referensi SPBE yang sesuai dengan arsitektur SPBE yang dipilih.'"
              label="Kategori Infrastruktur"
              label-for="vi-inf_category_id"
            >
              <v-select
                id="vi-inf_category_id"
                v-model="inf_category_id"
                name="inf_category_id"
                :options="daftarKategoriInfra"
                :disabled="(daftarKategoriInfra.length === 0) ? true : false"
                placeholder="Kategori Infrastruktur"
                label="nama"
                @input="SetNamaKategoriInfra()"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="`Nama ${inf_domain_id.name} yang digunakan.`"
              :label="`Nama ${nama_infra}`"
              label-for="vi-nama"
            >
              <validation-provider
                #default="{ errors }"
                :name="`Nama ${nama_infra}`"
                rules="required"
              >
                <b-form-input
                  id="vi-nama"
                  v-model="appData.nama"
                  required
                  name="nama"
                  :placeholder="`Nama ${nama_infra}`"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Merupakan status kegiatan/pengadaan Infra.'"
              label="Status Kegiatan"
              label-for="vi-inf_status_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Status kegiatan"
                rules="required"
              >
                <v-select
                  id="vi-inf_status_id"
                  v-model="inf_status_id"
                  required
                  name="inf_status_id"
                  :options="referenceData.ref_status_kegiatan"
                  placeholder="Status Kegiatan"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

        <template v-if="inf_domain_id.domain_infra_id === 3 && inf_area_id.area_infra_id === 1">
          <!-- Type Sistem Integrasi : Jaringan Intra Pemerintah -->
          <infra-sis-jar
            ref="sisJar"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <template v-else-if="inf_domain_id.domain_infra_id === 3 && inf_area_id.area_infra_id === 7">
          <!-- Type Sistem Integrasi : Sistem Penghubung Layanan Pemerintah -->
          <infra-sis-hub
            ref="sisJar"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <template v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 3">
          <!-- Type Platform : Komputasi Awan -->
          <infra-plat-cloud
            ref="platCloud"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <template
          v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 8"
        >
          <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Software -->
          <infra-plat-kia-soft
            ref="platKiaSoft"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <template
          v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 7"
        >
          <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Server -->
          <infra-plat-kia-server
            ref="platKiaServer"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <template
          v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 5"
        >
          <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Media Penyimpanan -->
          <infra-plat-kia-storage
            ref="platKiaStorage"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <template
          v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 3"
        >
          <infra-plat-kia-network
            ref="platKiaNetwork"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <template
          v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 4"
        >
          <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Keamanan -->
          <infra-plat-kia-security
            ref="platKiaSecurity"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <template
          v-else-if="inf_domain_id.domain_infra_id === 2 && inf_area_id.area_infra_id === 2 && inf_category_id.kategori_infra_id === 6"
        >
          <!-- Type Platform : Kerangka Infrastruktur dan Aplikasi : Perangkat Keras Periferal -->
          <infra-plat-kia-periferal
            ref="platKiaPeriferal"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <!-- Type Fasilitas Komputasi : ALL -->
        <template v-else>
          <infra-fasil
            ref="fasil"
            :inf-data="appData"
            :action-step="reqData"
            :data-referensi="referenceData"
          />
        </template>

        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>

          <b-col
            md="6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Total Anggaran Program sesuai dengan Renja'"
              :label="`Total Anggaran ${(appData.total_anggaran) ? ': Rp. ' + Number(appData.total_anggaran).toLocaleString() : ''}`"
              label-for="vi-total_anggaran"
            >
              <validation-provider
                #default="{ errors }"
                name="Total Anggaran"
                rules="required"
              >
                <b-form-input
                  id="vi-total_anggaran"
                  v-model="appData.total_anggaran"
                  required
                  name="total_anggaran"
                  placeholder="Total Anggaran"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Sumber Dana Program sesuai dengan Renja'"
              label="Sumber Dana"
              label-for="vi-master_sumber_dana_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Sumber Dana"
                rules="required"
              >
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="appData.sumber_dana"
                  required
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Pilih Salah Satu"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Jenis Belanja Program sesuai dengan Renja'"
              label="Jenis Belanja"
              label-for="vi-master_jenis_belanja_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Jenis Belanja"
                rules="required"
              >
                <v-select
                  id="vi-master_jenis_belanja_id"
                  v-model="appData.jenis_belanja"
                  required
                  name="master_jenis_belanja_id"
                  :options="referenceData.ref_jenis_belanja"
                  placeholder="Pilih Salah Satu"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Jenis Pengadaan Program sesuai dengan Renja'"
              label="Jenis Pengadaan"
              label-for="vi-master_jenis_pengadaan_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Jenis Pengadaan"
                rules="required"
              >
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="appData.jenis_pengadaan"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Pilih Salah Satu"
                  label="nama"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="appData.kak_uri !== null"
            md="6"
            class="mb-50"
          >
            <h5>Kerangka Acuan Kerja</h5>
            <p>{{ appData.kak_uri.substring(appData.kak_uri.lastIndexOf('/') + 1) }}
              <b-button
                variant="success"
                size="sm"
                class="mr-25 mb-25"
                @click="viewFile(appData.kak_uri)"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  size="14"
                /></b-button>
              <b-link
                :href="getDoc(appData.kak_uri)"
                class="btn btn-sm btn-info mr-25 mb-25"
                target="_blank"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="14"
                /></b-link>
            </p>
          </b-col>
          <b-col
            v-if="appData.additional_docs.length !== 0"
            md="6"
            class="mb-50"
          >
            <h5>Dokumen Tambahan</h5>
            <p
              v-for="(data, index) in appData.additional_docs"
              :id="`dt-${data.document_id}`"
              :key="index"
            >{{ data.document_uri.substring(data.document_uri.lastIndexOf('/') + 1) }}
              <b-button
                variant="success"
                size="sm"
                class="mr-25 mb-25"
                @click="viewFile(data.document_uri)"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  size="14"
                /></b-button>
              <b-link
                :href="getDoc(data.document_uri)"
                class="btn btn-sm btn-info mr-25 mb-25"
                target="_blank"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="14"
                /></b-link>
              <b-button
                variant="outline-danger"
                size="sm"
                class="mr-25 mb-25"
                @click="removeFile(data.document_id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="14"
                /></b-button>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Upload file Kerangka Acuan Kerja atau Term of Reference Kegiatan (dalam format .pdf)'"
              label="Ubah Kerangka Acuan Kerja (dalam format PDF)"
              label-for="file_kak"
            >
              <b-form-file
                ref="file_kak"
                v-model="file1"
                name="file_kak"
                class="mt-1"
                @input="checkFile"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="mb-50"
          >
            <b-form-group
              v-b-tooltip.hover.top="'Upload file Tambahan (dalam format .pdf)'"
              label="Tambah Dokumen Tambahan (dalam format PDF)"
              label-for="file_extra_doc"
            >
              <b-form-file
                ref="file_extra_doc"
                v-model="file2"
                name="file_extra_doc"
                class="mt-1"
                multiple
                @input="checkFile"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-button
              variant="outline-success"
              class="mt-0 mt-md-2"
              @click="simpanInfra"
            >
              <span>Simpan Infrastruktur</span>
            </b-button>
            <b-button
              v-if="dataSaved"
              variant="flat-success"
              size="sm"
              class="mt-0 mt-md-2 ml-50"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="14"
              /> Infratruktur Berhasil Disimpan
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>

    <div v-if="errorMsg">
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Error Found
        </h4>
        <div class="alert-body">
          <ul v-if="typeof errorMsg === 'object'">
            <li
              v-for="(item, index) in errorMsg"
              :key="index"
            >
              {{ index }}:
              <span
                v-for="(child, indexs) in item"
                :key="indexs"
              >
                {{ child }}
              </span>
            </li>
          </ul>
          <span v-else>{{ errorMsg }}</span>
        </div>
      </b-alert>
    </div>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Dokumen Tambahan?
      </b-card-text>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BAlert, BLink, BModal, BCardText, BForm, BButton, BCard, BFormFile, BFormInput, BRow, BCol, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import VuePdfApp from 'vue-pdf-app'

import InfraFasil from '../template-infra/InfraFasil.vue'
import InfraSisJar from '../template-infra/InfraSisJar.vue'
import InfraSisHub from '../template-infra/InfraSisHub.vue'
import InfraPlatCloud from '../template-infra/InfraPlatCloud.vue'
import InfraPlatKiaSoft from '../template-infra/InfraPlatKiaSoft.vue'
import InfraPlatKiaServer from '../template-infra/InfraPlatKiaServer.vue'
import InfraPlatKiaStorage from '../template-infra/InfraPlatKiaStorage.vue'
import InfraPlatKiaNetwork from '../template-infra/InfraPlatKiaNetwork.vue'
import InfraPlatKiaSecurity from '../template-infra/InfraPlatKiaSecurity.vue'
import InfraPlatKiaPeriferal from '../template-infra/InfraPlatKiaPeriferal.vue'

export default {
  components: {
    BCardText,
    BAlert,
    BModal,
    BLink,
    BForm,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,

    InfraFasil,
    InfraSisJar,
    InfraSisHub,
    InfraPlatCloud,
    InfraPlatKiaSoft,
    InfraPlatKiaServer,
    InfraPlatKiaStorage,
    InfraPlatKiaNetwork,
    InfraPlatKiaSecurity,
    InfraPlatKiaPeriferal,
    VuePdfApp,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    appData: {
      type: Object,
      default: () => {},
    },
    coreData: {
      type: Object,
      default: () => {},
    },
    referenceData: {
      type: Object,
      default: () => {},
    },
    actionStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
      userData: getUserData(),
      nama_infra: 'Pusat Data',
      data_utama_id: '',
      errorMsg: '',
      infrastruktur_id: '',
      dataSaved: false,
      reqData: 0,
      fileDoc: '',
      inf_domain_id: '',
      inf_area_id: '',
      inf_category_id: '',
      nama: '',
      inf_status_id: '',
      total_anggaran: '',
      sumber_dana: '',
      jenis_belanja: '',
      jenis_pengadaan: '',
      file1: null,
      file2: [],
      daftarAreaInfra: [],
      daftarKategoriInfra: [],
      childData: {},
      toDelData: '',
    }
  },
  watch: {
    referenceData() {
      this.getReference()
    },
    actionStep() {
      this.simpanInfra()
    },
  },
  mounted() {
    this.data_utama_id = Number(this.$route.params.id)
    this.getReference()
    // this.nama_infra = this.inf_domain_id.name
  },
  methods: {
    checkFile(input) {
      if (input.size > 100 * 1024 * 1024) {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Ukuran Dokumen Terlalu Besar`,
        }
      } else if (input.type !== 'application/pdf') {
        this.alert = true
        this.alertDetail = {
          variant: 'danger',
          title: 'Error Found',
          text: `${input.name}: Jenis Dokumen Tidak Sesuai`,
        }
      } else {
        this.alert = false
      }
    },
    getReference() {
      if (Object.keys(this.referenceData).length !== 0) {
        this.referenceData.ref_domain_infra.map(contact => {
          if (contact.domain_infra_id === this.appData.domain_infra_id) {
            this.inf_domain_id = contact
          }
          return true
        })
        this.getAreaInfra()
        this.referenceData.ref_area_infra.map(contact => {
          if (contact.area_infra_id === this.appData.area_infra_id) {
            this.inf_area_id = contact
            this.nama_infra = this.inf_area_id.nama
          }
          return true
        })
        this.getKategoriInfra()
        this.referenceData.ref_kategori_infra.map(contact => {
          if (contact.kategori_infra_id === this.appData.kategori_infra_id) {
            this.inf_category_id = contact
            this.nama_infra = this.inf_category_id.nama
          }
          return true
        })
        this.referenceData.ref_status_kegiatan.map(contact => {
          if (contact.nama === this.appData.status_kegiatan) {
            this.inf_status_id = contact
          }
          return true
        })
        this.referenceData.ref_jenis_belanja.map(contact => {
          if (contact.nama === this.appData.jenis_belanja) {
            this.appData.jenis_belanja = contact
          }
          return true
        })
        this.referenceData.ref_jenis_pengadaan.map(contact => {
          if (contact.nama === this.appData.jenis_pengadaan) {
            this.appData.jenis_pengadaan = contact
          }
          return true
        })
        this.referenceData.ref_sumber_dana.map(contact => {
          if (contact.nama === this.appData.sumber_dana) {
            this.appData.sumber_dana = contact
          }
          return true
        })
        this.referenceData.ref_kepemilikan.map(contact => {
          if (contact.nama === this.appData.kepemilikan) {
            this.appData.kepemilikan = contact
          }
          return true
        })
        this.referenceData.ref_jenis_lisensi.map(contact => {
          if (contact.nama === this.appData.lisensi_infrastruktur) {
            this.appData.license_type = contact
          }
          return true
        })
        this.referenceData.ref_data_tematik.map(contact => {
          if (contact.nama === this.appData.data_tematik) {
            this.appData.data_tematik_id = contact
          }
          return true
        })
        this.referenceData.ref_jenis_prosesor.map(contact => {
          if (contact.nama === this.appData.jenis_prosesor) {
            this.appData.processor_type = contact
          }
          return true
        })
        this.referenceData.ref_teknik_penyimpanan.map(contact => {
          if (contact.nama === this.appData.teknis_penyimpanan) {
            this.appData.storage_technic = contact
          }
          return true
        })
      }
    },
    viewFile(data) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    removeFile(value) {
      this.toDelData = value
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
    },
    deleteItem() {
      this.$http.post('/clearance/infrastruktur/file/delete', {
        document_id: this.toDelData,
        infrastruktur_id: this.appData.id,
      }, {
        params: {
          token: localStorage.getItem('userToken'),
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            document.getElementById(`dt-${this.toDelData}`).remove()
            this.clearDeleteItem()
          }
        })
    },
    getAreaInfra() {
      this.daftarAreaInfra.splice(0)
      this.inf_area_id = ''
      this.inf_category_id = null

      const filterVal = this.inf_domain_id.domain_infra_id
      const refData = this.referenceData.ref_area_infra
      refData.map(contact => {
        if (contact.domain_infra_id === filterVal) {
          this.daftarAreaInfra.push(contact)
        }
        return true
      })
    },
    getKategoriInfra() {
      this.daftarKategoriInfra.splice(0)
      this.inf_category_id = ''

      const filterVal = this.inf_area_id.area_infra_id
      this.nama_infra = this.inf_area_id.nama

      const refData = this.referenceData.ref_kategori_infra
      refData.map(contact => {
        if (contact.area_infra_id === filterVal) {
          this.daftarKategoriInfra.push(contact)
        }
        return true
      })
    },
    SetNamaKategoriInfra() {
      this.nama_infra = this.inf_category_id.name
    },
    simpanInfra() {
      this.reqData += 1

      this.$refs.formInfra.validate()
        .then(success => {
          if (success) {
            this.saveInfra()
          } else {
            const data = {
              status: false,
              id: this.appData.id,
              msg: 'Terdapat Kesalahan Validasi Infrastruktur, Periksa Input Infrastruktur Anda.',
            }
            this.$emit('step-completed', data)
          }
        })
        .catch(error => {
          const data = {
            status: false,
            id: this.appData.id,
            msg: error.message,
          }
          this.errorMsg = error.message
          this.$emit('step-completed', data)
        })
    },
    saveInfra() {
      this.errorMsg = ''
      document.getElementById('loading-bg').style.display = 'block'
      const metaUtama = {
        infrastruktur_id: this.appData.id,
        instansi_id: this.appData.instansi_id,
        data_utama_id: this.coreData.data_utama_id,
        domain_infra_id: this.inf_domain_id.domain_infra_id,
        area_infra_id: this.inf_area_id.area_infra_id,
        nama: this.appData.nama,
        status_kegiatan_id: this.inf_status_id.status_kegiatan_id,
        total_anggaran: Number(this.appData.total_anggaran),
        sumber_dana_id: this.appData.sumber_dana.sumber_dana_id,
        jenis_belanja_id: this.appData.jenis_belanja.jenis_belanja_id,
        jenis_pengadaan_id: this.appData.jenis_pengadaan.jenis_pengadaan_id,
        bandwidth: this.appData.bandwidth,
        lokasi: this.appData.lokasi,
        unit_kerja: this.appData.unit_kerja,
        tier: this.appData.tier,
        sistem_pengaman: this.appData.sistem_pengaman,
        jenis: this.appData.jenis,
        nama_pemilik: this.appData.nama_pemilik,
        tipe_media_jaringan: this.appData.tipe_media_jaringan,
        media_lainnya: this.appData.media_lainnya,
        deskripsi: this.appData.deskripsi,
        nama_jaringan: this.appData.nama_jaringan,
        aplikasi_terhubung: this.appData.aplikasi_terhubung,
        biaya_layanan: this.appData.biaya_layanan,
        unit_pengembang: this.appData.unit_pengembang,
        unit_operasional: this.appData.unit_operasional,
        jangka_waktu_layanan: this.appData.jangka_waktu_layanan,
        perangkat_lunak: this.appData.perangkat_lunak,
        kapasitas_memori: this.appData.kapasitas_memori,
        kapasitas_penyimpanan: this.appData.kapasitas_penyimpanan,
        pemilik_lisensi: this.appData.pemilik_lisensi,
        validasi_lisensi: this.appData.validasi_lisensi,
        metode_akses_penyimpanan: this.appData.metode_akses_penyimpanan,
      }
      if (this.inf_category_id !== null) {
        metaUtama.kategori_infra_id = this.inf_category_id.kategori_infra_id
      }
      if (this.appData.kepemilikan) {
        metaUtama.kepemilikan_id = this.appData.kepemilikan.kepemilikan_id
      }
      if (this.appData.data_tematik_id) {
        metaUtama.data_tematik_id = this.appData.data_tematik_id.data_tematik_id
      }
      if (this.appData.processor_type) {
        metaUtama.jenis_prosesor_id = this.appData.processor_type.jenis_prosesor_id
      }
      if (this.appData.storage_technic) {
        metaUtama.teknis_penyimpanan_id = this.appData.storage_technic.teknis_penyimpanan_id
      }
      if (this.appData.license_type) {
        metaUtama.jenis_lisensi_id = this.appData.license_type.jenis_lisensi_id
      }

      this.$http.post('/clearance/infrastruktur', metaUtama, {
        params: {
          token: localStorage.getItem('userToken'),
          clearance_id: this.coreData.clearance_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            const status = true
            this.infrastruktur_id = res.data.data.infrastruktur_id

            const promise1 = new Promise(resolve => {
              if (this.file1) {
                const formFile = new FormData()
                formFile.append('clearance_id', this.coreData.clearance_id)
                formFile.append('data_utama_id', this.coreData.data_utama_id)
                formFile.append('infrastruktur_id', this.infrastruktur_id)
                formFile.append('kak_file', this.file1)

                this.$http.post('/clearance/infrastruktur/file/upload', formFile, {
                  params: {
                    token: localStorage.getItem('userToken'),
                  },
                })
                  .then(uplds => {
                    if (uplds.data.status === 'success') {
                      this.appData.kak_uri = uplds.data.kak_uri
                      resolve(true)
                    } else {
                      const data = {
                        status: false,
                        msg: uplds.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = uplds.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    }
                  })
                  .catch(error => {
                    const data = {
                      status: false,
                      msg: error.response.data.error,
                    }
                    document.getElementById('loading-bg').style.display = 'none'
                    this.errorMsg = error.response.data.error
                    this.$emit('step-completed', data)
                    resolve(false)
                  })
              } else {
                resolve(true)
              }
            })

            const promise2 = new Promise(resolve => {
              if (this.file2.length !== 0) {
                for (let i = 0; i < this.file2.length; i += 1) {
                  const formFile = new FormData()
                  formFile.append('clearance_id', this.coreData.clearance_id)
                  formFile.append('data_utama_id', this.coreData.data_utama_id)
                  formFile.append('infrastruktur_id', this.infrastruktur_id)
                  formFile.append('additional_files', this.file2[i])

                  this.$http.post('/clearance/infrastruktur/file/upload', formFile, {
                    params: {
                      token: localStorage.getItem('userToken'),
                    },
                  })
                    .then(uplds => {
                      if (uplds.data.status === 'success') {
                        this.appData.additional_docs.push(uplds.data.data[0])
                        resolve(true)
                      } else {
                        const data = {
                          status: false,
                          msg: uplds.data.error,
                        }
                        document.getElementById('loading-bg').style.display = 'none'
                        this.errorMsg = uplds.data.error
                        this.$emit('step-completed', data)
                        resolve(false)
                      }
                    })
                    .catch(error => {
                      const data = {
                        status: false,
                        msg: error.response.data.error,
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.errorMsg = error.response.data.error
                      this.$emit('step-completed', data)
                      resolve(false)
                    })
                }
              } else {
                resolve(true)
              }
            })

            Promise.all([promise1, promise2, status]).then(values => {
              if (values[0] && values[1] && values[2]) {
                const data = {
                  status: true,
                  value: values,
                  id: this.appData.id,
                  type: 'inf',
                  msg: {
                    data_utama_id: this.coreData.data_utama_id,
                    aplikasi_id: this.aplikasi_id,
                  },
                }
                document.getElementById('loading-bg').style.display = 'none'
                this.$refs.file_kak.reset()
                this.$refs.file_extra_doc.reset()
                this.file1 = null
                this.file2 = []
                this.dataSaved = true
                this.errorMsg = ''
                this.$emit('step-completed', data)
              }
            })
          } else {
            document.getElementById('loading-bg').style.display = 'none'
            this.errorMsg = `${res.data.message}: ${res.data.error}`
          }
        })
        .catch(error => {
          const data = {
            status: false,
            id: this.appData.id,
            type: 'inf',
            msg: error.response.data.error,
          }
          document.getElementById('loading-bg').style.display = 'none'
          this.errorMsg = error.response.data.error
          this.$emit('step-completed', data)
        })
    },
  },
}
</script>
